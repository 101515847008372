import Typography from '@components/atoms/Typography';
import { TypoVariant } from '@constants/atom';
import { MOBILE_MODE } from '@constants/size';
import styled from '@emotion/styled';
import { gray, white } from '@styles/Colors';
import { Radius } from '@styles/Radius';

type Props = {
  readonly title: string;
  readonly type?: 'text' | 'password';
  readonly value: string;
  readonly placeholder: string;
  readonly onChange: (value: string) => void;
};

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Title = styled(Typography)``;
const InputText = styled.input`
  margin-top: 8px;
  height: 48px;
  padding: 0 16px;
  font-size: 16px;
  border-radius: ${Radius.MEDIUM};
  border: 1px solid ${gray.gray4};
  color: ${white} !important;
  background-color: transparent !important;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${gray.gray4};
  }

  @media (${MOBILE_MODE}) {
    border-radius: ${Radius.SMALL};
    height: 40px;
    font-size: 14px;
  }
`;

export default function TextInput({ title, type = 'text', value, placeholder, onChange }: Props) {
  return (
    <Layout>
      <Title variant={TypoVariant.SH3} color={white}>
        {title}
      </Title>
      <InputText type={type} value={value} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} />
    </Layout>
  );
}
