import { TypoVariant } from '@constants/atom';
import { useMemo } from 'react';

export const useGetTypoSize = (variant: TypoVariant): [number, string, string] => {
  const [fontWeight, fontSize, lineHeight] = useMemo(() => {
    switch (variant) {
      case TypoVariant.H1:
        return [800, '90px', '124px'];
      case TypoVariant.H2:
        return [800, '66px', '90px'];
      case TypoVariant.H3:
        return [700, '52px', '72px'];
      case TypoVariant.H4:
        return [700, '40px', '60px'];
      case TypoVariant.H5:
        return [700, '32px', '50px'];
      case TypoVariant.H6:
        return [700, '28px', '46px'];
      case TypoVariant.SH1:
        return [700, '24px', '34px'];
      case TypoVariant.SH2:
        return [700, '20px', '28px'];
      case TypoVariant.SH3:
        return [700, '16px', '22px'];
      case TypoVariant.SH4:
        return [700, '14px', '20px'];
      case TypoVariant.SH5:
        return [700, '12px', '18px'];
      case TypoVariant.B1:
        return [400, '20px', '30px'];
      case TypoVariant.B2:
        return [400, '18px', '26px'];
      case TypoVariant.B3:
        return [400, '16px', '24px'];
      case TypoVariant.B4:
        return [400, '14px', '20px'];
      case TypoVariant.B5:
        return [400, '12px', '18px'];
      case TypoVariant.B6:
        return [400, '10px', '16px'];
    }
  }, [variant]);

  return [fontWeight, fontSize, lineHeight];
};
