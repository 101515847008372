import styled from '@emotion/styled';
import Image from 'next/image';
import LoginContent from './LoginContent';
import { ACCESS_TOKEN, getCookie } from '@utils/cookie';
import { useRouter } from 'next/router';
import { USER_LIST } from '@constants/routes/routes';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const BackgroundImage = styled(Image)`
  position: absolute;
  width: 100dvw;
  height: 100dvh;
`;
const LogoContainer = styled.div`
  position: absolute;
  left: 81.25%;
  right: 4.17%;
  top: 89.07%;
  bottom: 6.67%;
`;
const Logo = styled(Image)``;

export default function LoginForm() {
  const router = useRouter();
  const accessToken = getCookie(ACCESS_TOKEN);

  if (accessToken) {
    router.push(USER_LIST);
  }

  return (
    <Layout>
      <BackgroundImage
        src={'/assets/images/BackgroundV3.webp'}
        alt="login-background"
        layout="fill"
        objectFit="cover"
        priority
      />
      <LoginContent />
      <LogoContainer>
        <Logo src={'/assets/images/LogoWhite.png'} alt="bottom-logo" width={280} height={46} />
      </LogoContainer>
    </Layout>
  );
}
