import { ButtonColor } from '@constants/atom';
import { MOBILE_MODE } from '@constants/size';
import styled from '@emotion/styled';
import { useGetButtonColor } from '@hooks/atoms/useGetButtonColor';
import { Radius } from '@styles/Radius';
import { ReactNode } from 'react';

type Props = Styleable & {
  readonly children?: ReactNode;
  readonly width?: string;
  readonly color?: ButtonColor;
  readonly disabled?: boolean;
  readonly onClick?: () => void;
  readonly type?: 'button' | 'submit';
};

type CssProps = {
  readonly background: string;
  readonly textColor: string;
  readonly borderColor: string;
  readonly hoverBackgroundColor: string;
  readonly hoverTextColor: string;
  readonly hoverBorderColor: string;
};

const ButtonLayout = styled.button<CssProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 23px;
  border-radius: ${Radius.MEDIUM};
  border: 1px solid;
  cursor: pointer;

  background-color: ${({ background }) => background};
  color: ${({ textColor }) => textColor};
  border-color: ${({ borderColor }) => borderColor};

  &:hover {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
    color: ${({ hoverTextColor }) => hoverTextColor};
    border-color: ${({ hoverBorderColor }) => hoverBorderColor};
  }

  &:disabled {
    cursor: not-allowed;
  }

  /* mobile -> small */
  @media (${MOBILE_MODE}) {
    padding: 9px 23px;
  }
`;

const DEFAULT_WIDTH = '100%';

export default function ButtonWithIconV3({
  style,
  children,
  type = 'button',
  width = DEFAULT_WIDTH,
  color = ButtonColor.ContainedBlue,
  disabled = false,
  onClick,
}: Props) {
  const [background, textColor, borderColor, hoverBackgroundColor, hoverTextColor, hoverBorderColor] =
    useGetButtonColor(color, disabled);

  return (
    <ButtonLayout
      style={{ width, ...style }}
      type={type}
      disabled={disabled}
      background={background}
      textColor={textColor}
      borderColor={borderColor}
      hoverBackgroundColor={hoverBackgroundColor}
      hoverTextColor={hoverTextColor}
      hoverBorderColor={hoverBorderColor}
      onClick={onClick}>
      {children}
    </ButtonLayout>
  );
}
