import styled from '@emotion/styled';
import Image from 'next/image';
import LogoWhite from '@public/assets/images/LogoWhite.png';
import { FormEvent, useState } from 'react';
import TextInput from './TextInput';
import { useLoginFetch } from '@hooks/fetch/useAuthFetch';
import { useRouter } from 'next/router';
import ButtonWithIconV3 from '@components/atoms/ButtonWithIcon';
import Typography from '@components/atoms/Typography';
import { TypoVariant } from '@constants/atom';
import Icon from '@components/atoms/Icon';
import { white } from '@styles/Colors';
import { USER_LIST } from '@constants/routes/routes';

const Form = styled.form`
  position: absolute;
  left: 10.42%;

  top: 50%;
  transform: translate(0, -50%);

  padding: 120px 100px;
  width: 544px;
  height: 652px;

  display: flex;
  flex-direction: column;
  align-items: center;

  backdrop-filter: blur(10px);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
`;
const TitleLogo = styled(Image)``;
const InputContainer = styled.div`
  margin-top: 100px;
  width: 100%;
  margin-bottom: 48px;
`;
const TextInputContainer = styled.div`
  & + & {
    margin-top: 16px;
  }
`;
const LoginButton = styled(ButtonWithIconV3)``;

export default function LoginContent() {
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { loginFetchMutate, loginFetchIsLoading: isLoading } = useLoginFetch();

  const onChangeEmail = (email: string) => {
    setEmail(email);
  };
  const onChangePassword = (password: string) => {
    setPassword(password);
  };
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    loginFetchMutate(
      { email, password },
      {
        onSuccess: () => {
          router.replace(USER_LIST);
        },
      },
    );
  };

  return (
    <Form onSubmit={onSubmit}>
      <TitleLogo src={LogoWhite} width={255} height={44} />
      <InputContainer>
        <TextInputContainer>
          <TextInput title="아이디" value={email} placeholder="아이디를 입력해주세요" onChange={onChangeEmail} />
        </TextInputContainer>
        <TextInputContainer>
          <TextInput
            title="비밀번호"
            type="password"
            value={password}
            placeholder="비밀번호를 입력해주세요"
            onChange={onChangePassword}
          />
        </TextInputContainer>
      </InputContainer>
      <LoginButton style={{ width: '100%' }} type="submit" disabled={isLoading}>
        {isLoading ? (
          <Icon name="loading" />
        ) : (
          <Typography variant={TypoVariant.SH3} color={white}>
            {'로그인'}
          </Typography>
        )}
      </LoginButton>
    </Form>
  );
}
