import { ButtonColor } from '@constants/atom';
import { blue, gray, primary, red, white } from '@styles/Colors';
import { useMemo } from 'react';

export const useGetButtonColor = (color: ButtonColor, disabled: boolean) => {
  const TRANSPARENT = 'transparent';

  const [background, textColor, borderColor, hoverBackgroundColor, hoverTextColor, hoverBorderColor] = useMemo(() => {
    // disabled 분기 처리
    if (disabled) {
      const ContainedBtns = [
        ButtonColor.ContainedBlack,
        ButtonColor.ContainedGray,
        ButtonColor.ContainedBlue,
        ButtonColor.ContainedRed,
      ];

      if (ContainedBtns.includes(color)) {
        return [gray.gray4, white, gray.gray4, gray.gray4, white, gray.gray4];
      }

      const OutlinedBtns = [
        ButtonColor.OutlinedBlack,
        ButtonColor.OutlinedGray,
        ButtonColor.OutlinedBlue,
        ButtonColor.OutlinedRed,
      ];

      if (OutlinedBtns.includes(color)) {
        return [TRANSPARENT, gray.gray4, gray.gray4, TRANSPARENT, gray.gray4, gray.gray4];
      }

      const TransparentBtns = [
        ButtonColor.TransparentBlack,
        ButtonColor.TransparentGray,
        ButtonColor.TransparentBlue,
        ButtonColor.TransparentRed,
      ];

      if (TransparentBtns.includes(color)) {
        return [TRANSPARENT, gray.gray4, TRANSPARENT, TRANSPARENT, gray.gray4, TRANSPARENT];
      }
    }

    switch (color) {
      case ButtonColor.ContainedBlack:
        return [primary.gray, white, primary.gray, primary.gray, white, primary.gray];
      case ButtonColor.ContainedGray:
        return [gray.gray6, white, gray.gray6, gray.gray7, white, gray.gray7];
      case ButtonColor.ContainedBlue:
        return [primary.blue, white, primary.blue, blue.blue4, white, blue.blue4];
      case ButtonColor.ContainedRed:
        return [red.red1, red.red4, red.red1, red.red1, red.red4, red.red1];

      case ButtonColor.OutlinedBlack:
        return [TRANSPARENT, primary.gray, primary.gray, TRANSPARENT, primary.gray, primary.gray];
      case ButtonColor.OutlinedGray:
        return [TRANSPARENT, gray.gray6, gray.gray6, TRANSPARENT, gray.gray7, gray.gray7];
      case ButtonColor.OutlinedBlue:
        return [TRANSPARENT, primary.blue, primary.blue, TRANSPARENT, blue.blue4, blue.blue4];
      case ButtonColor.OutlinedRed:
        return [TRANSPARENT, red.red4, red.red4, TRANSPARENT, red.red4, red.red4];

      case ButtonColor.TransparentBlack:
        return [TRANSPARENT, primary.gray, TRANSPARENT, TRANSPARENT, primary.gray, TRANSPARENT];
      case ButtonColor.TransparentGray:
        return [TRANSPARENT, gray.gray6, TRANSPARENT, TRANSPARENT, gray.gray7, TRANSPARENT];
      case ButtonColor.TransparentBlue:
        return [TRANSPARENT, primary.blue, TRANSPARENT, TRANSPARENT, blue.blue4, TRANSPARENT];
      case ButtonColor.TransparentRed:
        return [TRANSPARENT, red.red4, TRANSPARENT, TRANSPARENT, red.red4, TRANSPARENT];
    }
  }, [color, disabled]);

  return [background, textColor, borderColor, hoverBackgroundColor, hoverTextColor, hoverBorderColor];
};
