const pixelize = <S extends number>(size: S) => `${size}px` as const;

const MOBILE = 480;
const MOBILE_PX = pixelize(MOBILE);
export const MOBILE_MODE = `(max-width: ${MOBILE_PX})` as const;

const TABLET = 770;
const TABLET_PX = pixelize(TABLET);
export const TABLET_MODE = `(max-width: ${TABLET_PX})` as const;

const DESKTOP = 1024;
const DESKTOP_PX = pixelize(DESKTOP);
export const DESKTOP_MODE = `(max-width: ${DESKTOP_PX})` as const;
